<template lang="pug">
#home.d-flex.w-100.flex-column.align-items-center.justify-content-center(style="height: 100vh;")
  h1 KONUK AFFILIATE
  .container-lg
    .services.text-center
      router-link.mr-2(:to="{ name: 'transfer.index' }") Transfer
      router-link.mr-2(:to="{ name: 'transfer.index' }") Tour
      router-link.mr-2(:to="{ name: 'transfer.index' }") Hotel
</template>

<script>
// @ is an alias to /src
import TransferHero from '@/components/TransferHero.vue'
import TransferSearchForm from '@/components/TransferSearchForm.vue'

export default {
  name: 'Home',
  components: {
    TransferHero,
    TransferSearchForm
  }
}
</script>
